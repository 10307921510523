// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MJ from "../../assets/MJ.png";
import AM from "../../assets/AM.jpg";
import E from "../../assets/E.png";
import AR from "../../assets/Armand.jpeg";
import NE from "../../assets/NE.jpg";
import fotosCentro1 from "../../assets/FotosCentro7.jpg";
import fotosCentro2 from "../../assets/FotosCentro8.jpg";
import fotosCentro3 from "../../assets/FotosCentro9.jpg";
import fotosCentro4 from "../../assets/FotosCentro10.jpg";
import fotosCentro5 from "../../assets/FotosCentro11.jpg";
import fotosCentro6 from "../../assets/FotosCentro12.jpg";
import buttonsCarousel from "../../assets/buttonsCarousel.png";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import TarjetaPersona from "./TarjetaPersona";
import TarjetaPersonaHorizontal from "./TarjetaPersonaHorizontal";
import Footer from "./Footer.js";

class Us extends Component {

	render() {
		
        const contenidoMJ = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Licenciada en psicología por la Universidad de Barcelona (especialidad escolar).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Colegiada n. 5682.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Especializada en el ámbito de la psicología educativa.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrado en orientación y asesoramiento psicopedagógico a escuelas.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Formación en terapia familiar sistémica.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga de la Escola Infantil Mafalda de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga del Centre d’Estudis Montseny de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga de la Escola Infantil Mainada de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Fundadora y directora de GAPI.</p>];

        const contenidoMJCatala = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Llicenciada en psicologia per la Universitat de Barcelona (especialitat escolar).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Col·legiada n. 5682.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Especialitzada en l'àmbit de la psicologia educativa.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrau en orientació i assessorament psicopedagògic a escoles.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Formació en teràpia familiar sistèmica.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga de l'Escola Infantil Mafalda de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga del Centre d’Estudis Montseny de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga de l'Escola Infantil Mainada de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Fundadora i directora de GAPI.</p>];

        const contenidoAlicia = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Licenciada en psicología por la Universidad de Barcelona (especialidad clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga General Sanitaria.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Colegiada 4061.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Logopeda. Colegiada 3.059.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Dificultades de Aprendizaje.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Neuropsicologia.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Reorganización funcional cerebral.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Alzheimer y demencias.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Terapia Miofuncional aplicada a la Ortodoncia.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Terapeuta en el Centro de Rehabilitación Neurológica Manfred (de 2005 a 2023).</p>]

        const contenidoAliciaCatala = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Llicenciada en psicologia per la Universitat de Barcelona (especialitat clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga General Sanitària.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Col·legiada 4061.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Logopeda. Col·legiada 3.059.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Dificultats d'Aprenentatge.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Neuropsicologia.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Reorganització funcional cerebral.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Alzheimer i demències.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Teràpia Miofuncional aplicada a l'Ortodòncia.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Terapeuta al Centre de Rehabilitació Neurològica Manfred (de 2005 a 2023).</p>]

        const contenidoEncarna = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Licenciada en psicología por la Universidad de Barcelona (especialidad clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga General Sanitaria.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Colegiada 6576.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrado en Psicología Clínica infanto-juvenil.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrado en Psicoterapia de niños y adolescentes.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master de perfeccionamiento en Psicoterapia de niños, adolescentes y adultos.</p>]

        const contenidoEncarnaCatala = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Llicenciada en psicologia per la Universitat de Barcelona (especialitat clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga General Sanitària.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Col·legiada 6576.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrau en Psicologia Clínica infanto-juvenil.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Postgrau en Psicoteràpia de nens i adolescents.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster de perfeccionament en Psicoteràpia de nens, adolescents i adults.</p>]

        const contenidoArmand = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Licenciado en psicología por la Universitat Ramon Llull (mención clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicólogo General Sanitario.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Colegiado 31650.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Master en Psicología General Sanitaria.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Formación en psicoterapia de infantes, adolescentes y adultos.</p>]

        const contenidoArmandCatala = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Llicenciat en psicologia per la Universitat Ramon Llull (menció clínica).</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòleg General Sanitari.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Col·legiat 31650.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Psicologia General Sanitària.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Formació en psicoteràpia infants, adolescents i adults.</p>]

        const contenidoNeus = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Licenciada en psicología por la Universidad de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicóloga General Sanitaria.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Colegiada 19517.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Máster Oficial en Terapias Psicológicas de Tercera Generación.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Formación en psicoterapia de infantes, adolescentes y adultos.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Certificado Internacional en Disciplina Positiva para Familias.</p>]

        const contenidoNeusCatala = [<p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Llicenciada en psicologia per la Universitat de Barcelona.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Psicòloga General Sanitària.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Col·legiada 19517.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster en Psicopatologia Clínica infanto-juvenil.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Màster Oficial en Teràpies Psicològiques de Tercera Generació.</p>,
        <p style={{margin: '0em 0em 0.1em', fontSize: '18px'}}>- Certificat Internacional en Disciplina Positiva per a Famílies.</p>]

        let {mobile, language} = this.props;

		return (
            <div className={styles.backgroundUs}>
            <PerfectScrollbar>
                <div className={styles.AppBody}>

                    {/* <div style={{backgroundColor: "#ffffff", width: "100vw"}}>
                        <div style={{flexDirection: "row", display: "flex"}}>
                            <img src={cuadroPasillo} style={{height: "auto", width: "50%", objectFit: "cover"}}></img>
                            <div style={{padding: "7vw"}} className={styles.text}>
                                <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                    <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>Quienes somos</p>
                                    <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                                </div>
                                <p>El gabinete de psicología GAPI, creado el año 1989, está formado por un equipo de profesionales que ofrece atención especializada y personalizada a niños, niñas, adolescentes y adultos, en los ámbitos de la psicología clínica, la psicopedagogía y la logopedia.</p>
                                <p>A lo largo de su trayectoria, GAPI se ha consolidado como centro dedicado a la asistencia y a la prevención.</p>
                                <p>Se trabaja en coordinación con otros profesionales, del ámbito escolar, social y de la salud, que desarrollan su función en la red pública o en centros privados.</p>
                                <p>En la atención que se ofrece a niños, niñas y adolescentes se realiza un seguimiento periódico con la familia, con el fin de proporcionar orientación y asesoramiento.</p>
                            </div>
                        </div>
                    </div>*/}

                    {/* <div style={{flexDirection: "row", display: "flex", marginTop: "4vw"}}>
                        <TarjetaPersona foto={MJ} nombre="MARIA JOSÉ CEJAS CONTRERAS" contenido={contenidoMJ}></TarjetaPersona>
                        <TarjetaPersona foto={AM} nombre="ALICIA MARTÍNEZ TORRALBA" contenido={contenidoAlicia}></TarjetaPersona>
                    </div>
                    <div style={{flexDirection: "row", display: "flex", marginBottom: "4vw"}}>
                        <TarjetaPersona foto={E} nombre="ENCARNA" contenido={contenidoEncarna}></TarjetaPersona>
                    </div> */}
                    <div style={{flexDirection: "column", display: "flex", marginTop: "2vw", marginBottom: "2vw"}}>
                        <TarjetaPersonaHorizontal foto={MJ} nombre="MARIA JOSÉ CEJAS CONTRERAS" contenido={language==0?contenidoMJCatala:contenidoMJ} mobile={mobile}></TarjetaPersonaHorizontal>
                        <TarjetaPersonaHorizontal foto={AM} nombre="ALICIA MARTÍNEZ TORRALBA" contenido={language==0?contenidoAliciaCatala:contenidoAlicia} mobile={mobile}></TarjetaPersonaHorizontal>
                        <TarjetaPersonaHorizontal foto={E} nombre="ENCARNA CRESPO NEVADO" contenido={language==0?contenidoEncarnaCatala:contenidoEncarna} mobile={mobile}></TarjetaPersonaHorizontal>
                        <TarjetaPersonaHorizontal foto={AR} nombre="ARMAND DIESTE FONTCUBERTA" contenido={language==0?contenidoArmandCatala:contenidoArmand} mobile={mobile}></TarjetaPersonaHorizontal>
                        <TarjetaPersonaHorizontal foto={NE} nombre="NEUS BOFILL TEJADA" contenido={language==0?contenidoNeusCatala:contenidoNeus} mobile={mobile}></TarjetaPersonaHorizontal>
                    </div>

                    <div style={{width: "100vw", backgroundColor: "#ffffff", display: "flex", flexDirection: "row"}}>
                        <div style={{paddingTop: "80px", paddingBottom: "40px"}}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%"}}>
                                <p className={styles.title}>{language==0?"El centre":"El centro"}</p>
                                <div className={styles.separationLine}></div>
                            </div>
                            {/* <div style={{width: "100vw", marginTop: "5vw", marginBottom: "5vw"}}>
                                <CarouselProvider
                                    naturalSlideWidth={100}
                                    naturalSlideHeight={600}
                                    totalSlides={6}
                                    infinite={true}
                                    className={styles.carouselUs}
                                >
                                    <ButtonBack className={styles.bannerBackButtonUs}>◀</ButtonBack>
                                    <Slider moveThreshold={0.25} className={styles.bannerSlider}>
                                        <Slide className={styles.bannerSlideUs} index={0}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro6}></img>
                                        </Slide>
                                        <Slide className={styles.bannerSlideUs} index={1}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro1}></img>
                                        </Slide>
                                        <Slide className={styles.bannerSlideUs} index={2}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro2}></img>
                                        </Slide>
                                        <Slide className={styles.bannerSlideUs} index={3}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro4}></img>
                                        </Slide>
                                        <Slide className={styles.bannerSlideUs} index={4}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro3}></img>
                                        </Slide>
                                        <Slide className={styles.bannerSlideUs} index={5}>
                                            <img style={{objectFit:"cover", width: "80vw"}} src={fotosCentro5}></img>
                                        </Slide>
                                    </Slider>
                                    <ButtonNext className={styles.bannerNextButtonUs}>◀</ButtonNext>
                                </CarouselProvider>
                            </div> */}

                            <div className={styles.textImage}>
                                {/* <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img> */}
                                
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro1} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro1, true)}/>
                                </div>
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro2} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro2, true)}/>
                                </div>
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro4} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro4, true)}/>
                                </div>
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro3} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro3, true)}/>
                                </div>
                            </div>
                            <div className={styles.textImage}>
                                {/* <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img>
                                <img src={neuro} className={styles.image}></img> */}
                                
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro5} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro5, true)}/>
                                </div>
                                <div style={{width: "25vw", height: "20vw", justifyContent: "center", justifyItems: "center", cursor: "pointer", borderRadius: "2px"}}>
                                    <img src={fotosCentro6} style={{height: "95%", width: "95%", objectFit: "cover", borderRadius: "2px"}} onClick={() => this.props.openImg(fotosCentro6, true)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    {/* <div style={{width: "100vw", backgroundColor: "#e8f8ff"}}>
                    <p style={{height: "auto", textAlign: "center", paddingBottom: "10px", paddingTop: "10px"}}>{language==0?"Gabinet de Psicologia GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com":"Gabinete de Psicología GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com"}</p>
                    </div> */}
                    <Footer language={language} top={mobile?"0px":"-128px"} bgColor={mobile?"white":null}></Footer>
                </div>
            </PerfectScrollbar>
            </div>
		);

	}

}

export default Us;
